	/*
  	Flaticon icon font: Flaticon
  	Creation date: 06/09/2018 06:53
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/font/Flaticon.eot");
  src: url("../fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/font/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/font/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.flaticon-instagram:before { content: "\f100"; }
.flaticon-camera-shutter:before { content: "\f101"; }
.flaticon-video-camera:before { content: "\f102"; }
.flaticon-video-player:before { content: "\f103"; }
.flaticon-video-player-1:before { content: "\f104"; }