@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap');

body {
  scrollbar-width: none;
  overflow-x: hidden !important; 
}

/* Custom scrollbar styles */
.scrollable-list {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #191a1c;
  padding-left: 10px;
}

/* Define the scrollbar track color */
.scrollable-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Define the scrollbar thumb color */
.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #333333;
}

.section-1-bg-img {
  background-image: url("https://maxwin.events/images/bg-2.png");
}
.section-2-bg-img {
  background-image: url("https://maxwin.events/images/bg-2.png");
}

.footer-section {
  padding: 50px 0;
}

.footer-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  display: inline-block;
  margin-right: 10px;
}

.footer-section ul li:last-child {
  margin-right: 0;
}

.footer-section ul li a {
  color: white;
}

.footer-section p {
  color: #777;
}
.term-header {
  font-size: 11pt;
  color: white !important;
}


.scroll-up-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-up-btn.show {
  opacity: 1;
}


.libre-barcode {
  font-family: "Libre Barcode 39", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size:3rem;
}
